<template>
  <base-layout>
    <h1 class="title">Thank You</h1>
    <p class="content">
      The payment following arrangement has been created. Please print for your
      records.
    </p>
    <h2 class="subtitle">Payment Info</h2>
    <payment-info :info="$store.state.arr.paymentInfo" />
    <h2 class="subtitle">Schedule</h2>
    <schedule
        :arr="$store.state.arr.arrangement"
        :fee="feeResult.amount"
        :include-penalty="instanceConfig.includePenalty"
        :include-interest="includeInterest(false)" />
    <fee-message />
  </base-layout>
</template>
<script>
import BaseLayout from "@/components/BaseLayout.vue";
import Schedule from "@/components/arrangement/Schedule.vue";
import PaymentInfo from "@/components/payment/PaymentInfo.vue";
import FeeMessage from "@/views/payment/overtime/FeeMessage";
import { mapState, mapGetters } from "vuex";

export default {
  components: { BaseLayout, Schedule, PaymentInfo, FeeMessage },
  computed: {
    ...mapState(["instanceConfig"]),
    ...mapState("pay", ["feeResult"]),
    ...mapGetters("arr", ["includeInterest"])
  }
};
</script>
